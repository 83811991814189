import React, {useState, useEffect} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button, Label,
} from 'reactstrap';
import {connect} from 'react-redux';
import {createDealer} from '../../store/dealer/actions';
import {withRouter, Link, useHistory} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

// availity-reactstrap-validation
import {AvForm, AvField, AvCheckboxGroup, AvCheckbox} from 'availity-reactstrap-validation';
import '@availity/reactstrap-validation-select/styles.scss';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ListCountries from '../../components/Lists/ListCountries';
import {apiError} from '../../store/auth/login/actions';

const DealerAdd = (props) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');

  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
    if (props.data && props.data.success) {
      setSuccessMsg(true);
    } else if (props.data && props.data.error) {
      setErrorMsg(props.data.error);
    }
  }, [props.data]);

  function handleSelectCountry(selectedCountry) {
    setSelectedCountry(selectedCountry);
  }

  function handleValidSubmit(event, values) {
    setIsLoading(true);

    const dealer = {
      name: values.name,
      street: values.street,
      street_no: values.street_no,
      zip_code: values.zip_code,
      city: values.city,
      country: selectedCountry.name,
      phone: values.phone,
      email: values.email,
      www: values.www,
      flags: values.flags,
    };
    props.createDealer(dealer);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            parent="/dealers"
            title="Dealers"
            breadcrumbItem="Add Dealer"
          />

          <Row>
            <Col xs="12">
              <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}>
                {errorMsg ?
                  <SweetAlert
                    title="Error!"
                    danger
                    showConfirm={true}
                    showCancel={false}
                    confirmBtnBsStyle="danger"
                    closeOnConfirm={true}
                    onConfirm={() => {
                      setErrorMsg(false);
                    }}
                  >
                    {errorMsg}
                  </SweetAlert> : ''
                }
                {successMsg ? <SweetAlert
                  title="Good job!"
                  success
                  showConfirm={true}
                  showCancel={false}
                  confirmBtnBsStyle="yellow"
                  onConfirm={() => {
                    history.push('/dealers');
                  }}
                >
                                    The dealer has been added!
                </SweetAlert> : null}
                <Card>
                  <CardBody>
                    <CardTitle>Dealer Informations</CardTitle>
                    <CardSubtitle
                      className="mb-3"
                    >
                        Fill all information below
                    </CardSubtitle>
                    <Row>
                      <Col md="6">
                        <AvField
                          name="name"
                          value=""
                          className="form-control"
                          placeholder="Name *"
                          label="Name"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid name."
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <AvField
                          name="street"
                          value=""
                          className="form-control"
                          placeholder="Street *"
                          label="Street"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid street."
                        />
                      </Col>
                      <Col md="2">
                        <AvField
                          name="street_no"
                          value=""
                          className="form-control"
                          placeholder="Street No *"
                          label="Street No"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid street no."
                        />
                      </Col>
                      <Col md="2">
                        <AvField
                          name="zip_code"
                          value=""
                          className="form-control"
                          placeholder="Zip Code *"
                          label="Zip Code"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid zip code."
                        />
                      </Col>
                      <Col md="3">
                        <AvField
                          name="city"
                          value=""
                          className="form-control"
                          placeholder="City *"
                          label="City"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid city."
                        />
                      </Col>
                      <Col md="3">
                        <Label htmlFor="country"
                          className="col-form-label">Country</Label>
                        <ListCountries onChange={(country) => {
                          handleSelectCountry(country);
                        }}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <AvField
                          name="phone"
                          value=""
                          className="form-control"
                          placeholder="Phone *"
                          label="Phone"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid phone number."
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <AvField
                          name="email"
                          value=""
                          className="form-control"
                          placeholder="Email Address*"
                          label="Email"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a valid email address."
                        />
                      </Col>
                      <Col md="4">
                        <AvField
                          name="www"
                          value=""
                          className="form-control"
                          placeholder="Web Address *"
                          label="Web Address"
                          type="text"
                          validate={{required: {value: true}}}
                          errorMessage="Please provide a web address."
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <AvCheckboxGroup
                          name="flags"
                          value=""
                          label="Options"
                          helpMessage="Select an option."
                          validate={{required: {value: true}}}
                          errorMessage="Please select at least one option."
                        >
                          <AvCheckbox label="Can see downloads Textile" value="TEXTILE" />
                          <AvCheckbox label="Can see downloads Non-Textile" value="NON_TEXTILE" />
                        </AvCheckboxGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Row className="my-4">
                  <Col sm="6">
                    <Link to="/dealers"
                      className="btn d-none d-sm-inline-block btn-link"
                    >
                      <i className="mdi mdi-arrow-left mr-1"/>
                        Back to Dealers Overview
                    </Link>
                  </Col>
                  <Col sm="6">
                    <div className="text-sm-right">
                      {isLoading ?
                        <Button
                          className="btn btn-yellow"
                          type="submit"
                          disabled={true}
                        >
                          <i className="bx bx-loader bx-spin font-size-16
                          align-middle mr-2"/>
                            Submit Dealer
                        </Button> :
                        <Button className="btn btn-yellow" type="submit">
                          <i className="mdi mdi-plus-thick mr-1"/>
                            Submit Dealer
                        </Button>
                      }
                    </div>
                  </Col>
                </Row>

              </AvForm>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = (state) => {
  const {data} = state.Dealer;
  state.Dealer = {};
  return {data};
};

export default withRouter(connect(
    mapStatetoProps, {createDealer, apiError},
)(DealerAdd));
